import './public-path.js'
// babel-preset-env will replace this with just the polyfills we need based on the browsers we support
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'drag-drop-touch'

import React from 'react'
import {render} from 'react-dom'
import {Provider} from 'react-redux'

import t from 'format-message'

import {Alert} from '@instructure/ui-alerts'

import createRaven from './raven'

import {initializeStore, launchParams} from './launchParams'
import store from './store'
import setUpDevHelpers from './util/devHelpers'
import initTranslations from './initTranslations'
import {ThemeProvider} from './initThemes'

export const root = document.getElementById('root')
export const raven = createRaven()

let sessionStorage = null
let localStorage = null
try {
  sessionStorage = window.sessionStorage
  localStorage = window.localStorage
} catch (e) {
  // Crossdomain and 3rd-party cookies disabled
}

// Initialize the store with launch params
const {
  accessToken,
  assignmentId,
  courseWorkflowState,
  currentUser,
  highContrast,
  itemBanksScope,
  launchUrl,
  platform,
  returnToUrl,
  rldbLaunch,
  rcsHost,
  rcsJwt,
  contextType,
  canvasContextId,
  canvasLocalContextId,
  canvasToolId,
  userCanvasId,
  canvasHost,
  moduleLaunch,
  canvasAssignmentId,
  timeZone,
  restrictQuantitativeData,
  gradingScheme,
  launchToken,
  readOnly,
} = launchParams(self.location.search, sessionStorage, localStorage)

function initializationError(ex) {
  if (ex) {
    raven.captureException(ex)
  } else {
    raven.captureMessage('Failed to launch')
  }
  render(
    <Alert margin="large" variant="error">
      {t('Oops, something went wrong. We have logged the error and will investigate.')}
    </Alert>,
    root,
  )
}

initTranslations()
  .then(translations => {
    if (accessToken) {
      initializeStore(store, {
        accessToken,
        assignmentId,
        courseWorkflowState,
        currentUser,
        itemBanksScope,
        launchUrl,
        moduleLaunch,
        platform,
        returnToUrl,
        rldbLaunch,
        rcsHost,
        rcsJwt,
        contextType,
        canvasContextId,
        canvasLocalContextId,
        canvasToolId,
        userCanvasId,
        canvasHost,
        sdkTranslations: translations.quizSdk,
        timeZone,
        canvasAssignmentId,
        restrictQuantitativeData,
        gradingScheme,
        launchToken,
        readOnly,
      })

      setUpDevHelpers()

      store.router
        .on('route', async (args, routing) => {
          const {title, view} = await routing

          if (title) {
            // eslint-disable-next-line immutable/no-mutation
            document.title = t('Quizzes - { title }', {title})
          }

          render(
            <ThemeProvider rldbLaunch={rldbLaunch} highContrast={highContrast}>
              <Provider store={store}>{view}</Provider>
            </ThemeProvider>,
            root,
          )
        })
        .start()
    } else if (returnToUrl) {
      // If we do not have an access token, the session has expired & there isn't
      // much we can do about it.
      //
      // Send the user back to the last known return to url.
      window.location.replace(returnToUrl)
    } else {
      // If we don't have an access token or a return url, something has gone
      // horribly wrong with the launch.
      //
      // Log an error and display an alert.
      initializationError()
    }
  })
  .catch(ex => initializationError(ex))
